import bravoImg from '../img/spe/bravo-spe.webp';

const Bravo = () => {
    return(
        <section className='container'>
            <h2 className='border-bottom-black font-size-3 padding-bottom-1 margin-top-3 margin-bottom-3 color-black'>
                Bravo :
            </h2>

            <div className='row'>
                <p className='col-sm-12 col-md font-size-2 color-green'>
                    L'unité Bravo est constituée de force blindé. Cette dernière est une arme redoutable de soutien et 
                    de stratégie inter arme.  Elle accompagne les forces au sol dans le cadre de nombreuses missions : 
                    reconnaissance , annilhalation , contrôle du milieu et surtout les assauts dont la destruction de 
                    cible prioritaire.
                </p>
                <div className='col-sm-12 col-md'>
                    <img src={bravoImg} alt='Bravo en action' className='border-black width-100' />
                </div>
            </div>

            <h2 className='border-bottom-black font-size-3 padding-top-3 padding-bottom-1 margin-top-3 margin-bottom-3 color-black'>
                Sous spécialisations :
            </h2>

            <div className='row font-size-1-5'>

                <div className='col-sm-6 col-md-4'>
                    <h3 className='font-size-2-5'>Blindé :</h3>
                    <ul className='color-green'>
                        <li>Commandant de char</li>
                        <li>Opérateur manœuvre</li>
                        <li>Maitre de tir</li>
                    </ul>
                </div>

                <div className='col-sm-6 col-md-4'>
                    <h3 className='font-size-2-5'>Reconnaissance blindé léger :</h3>
                    <ul className='color-green'>
                        <li>Accompagnement de l'infanterie de reconnaissance</li>
                    </ul>
                </div>

                <div className='col-sm-6 col-md-4'></div>
                
            </div>
        </section>
    )
}

export default Bravo;