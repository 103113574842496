import { Routes, Route, Link, Navigate } from 'react-router-dom';
import { useState } from 'react';
import header from './img/header.webp';
import logo from './img/logo.png';
import discord from './img/contact/discord.png';
import mail from './img/contact/mail.png';
import instagram from './img/contact/instagram.png';
import Home from './pages/Home';
import Join from './pages/Join';
import Rules from './pages/Rules';
import Media from './pages/Media';
import NotFound from './pages/NotFound';
import India from './pages/india';
import Eagle from './pages/eagle';
import Bravo from './pages/bravo';
import Ea from './pages/ea';

function App() {

  const [isActive, setActive] = useState("false");

  const addShadow = () => {
    setActive(!isActive);
  };

  return (
    <div className="App">

      <header>
        <img className='fixed z-index-2 width-100' src={header} fetchpriority="high" alt='Cérémonie militaire' />
        <img className='z-index-1 width-10 margin-top-3 margin-left-3' src={logo} alt='Logo du 1RCP' />
        <h1 className='font-size-3 color-white margin-top-0 margin-left-20 padding-bottom-10-desktop padding-bottom-9-mobile padding-bottom-8-mobile'>
          1er Régiment de Chasseurs Parachutistes<br />
          Arma III - Milsim
        </h1>
      </header>

      <main>
        <nav className='navbar z-index-0 sticky top-0 background-brown border-top-black border-bottom-black'>
          <div class="container justify-content-end">

            <button onClick={addShadow} className={isActive ? "navbar-toggler opacity-07 opacity-1-hov border-radius-0 box-shadow" : "navbar-toggler opacity-07 opacity-1-hov border-radius-0 box-shadow-none"} class="navbar-toggler opacity-07 opacity-1-hov border-radius-0 box-shadow" aria-label='Menu' type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
              <span className="navbar-toggler-icon"></span>
            </button>


            <div className="offcanvas offcanvas-start background-white" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
              <div className="offcanvas-header">
                <h5 className="offcanvas-title font-size-2-5" id="offcanvasNavbarLabel">Menu</h5>
                <button onClick={addShadow} type="button" className={isActive ? "btn-close border-radius-0 box-shadow-none" : "btn-close border-radius-0 box-shadow"} data-bs-dismiss="offcanvas" aria-label="Close"></button>
              </div>
              <div className="offcanvas-body">
                <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">

                  <Link to='/' className='color-green decoration-none border-top-black font-size-2 decoration-underline-hov color-green-hov margin-top-1 padding-bottom-1'>Accueil</Link>

                  <Link to='/règlement' className='color-green decoration-none border-top-black font-size-2 decoration-underline-hov color-green-hov margin-top-1 padding-bottom-1'>Règlement</Link>

                  <Link to='/engagement' className='color-green decoration-none border-top-black font-size-2 decoration-underline-hov color-green-hov margin-top-1 padding-bottom-1'>S'engager</Link>

                  <Link to='/médias' className='color-green decoration-none border-top-black border-bottom-black font-size-2 decoration-underline-hov color-green-hov margin-top-1 padding-bottom-1'>Médias</Link>

                </ul>
              </div>
            </div>
          </div>
        </nav>

        <Routes>
          <Route path='/' element={<Home />}></Route>
          <Route path='/règlement' element={<Rules />}></Route>
          <Route path='/engagement' element={<Join />}></Route>
          <Route path='/médias' element={<Media />}></Route>
          <Route path='/india' element={<India />}></Route>
          <Route path='/eagle' element={<Eagle />}></Route>
          <Route path='/bravo' element={<Bravo />}></Route>
          <Route path='/*' element={<Navigate to="/erreur-404" />}></Route>
          <Route path='/erreur-404' element={<NotFound />}></Route>
          <Route path='/82' element={<Ea />}></Route>
        </Routes>
      </main>

      <footer>
        <div className='container'>
          <div className='row margin-top-2 padding-bottom-2'>

            <small className='col md-6 text-center font-size-1'>
              L'équipe du 1 RCP sur arma n'est en aucun cas affilié à une quelconque structure en lien réelle avec le ministère de la 
              défense , ou le régiment concerné dans la vie réelle. Toutes nos missions , contextes et toutes autres ressemblance 
              avec des faits réels ne sont que fortuites et n'ont pas pour but de porter atteinte aux différentes institutions qui 
              travaillent chaque jour afin de remplir de vrai objectifs et d'apporter une réelle contribution à la sureté nationale, ou 
              aux intérêts géopolitiques , de lutte contre le terrorisme. Notre équipe ne fait que de reprendre le nom d'un régiment 
              existant et ne souhaite en aucun cas porter atteinte à l'intégrité de ce dernier de par nos communications , actions. 
              Nous sommes un groupe de passionnés qui tentont seulement de prendre du plaisir sur un simulateur permettant de 
              reproduire une forme de réalisme avec la limite d'un jeu qui se veut ludique et non pas à des fins de malveillance envers 
              nos armées , nos soldats.
            </small>

            <div className='col-md-6'>

              <div className='row rows-col-4'>
                <div className='col'>
                  <a target='blank' href='https://discord.gg/BW4HjZhYX4'>
                    <img className='width-30 margin-left-50 margin-left-35 margin-top-30 margin-bottom-10 opacity-08 opacity-1-hov' src={discord} alt='Discord' />
                  </a>
                </div>
                <div className='col'>
                  <a target='blank' href='mailto:1RCP.Milsim.Team@gmail.com'>
                    <img className='width-30 margin-left-50 margin-left-35 margin-top-30 margin-bottom-10 opacity-08 opacity-1-hov' src={mail} alt='E-mail' />
                  </a>
                </div>
                <div className='col'>
                  <a target='blank' href='https://www.instagram.com/1rcp_milsim_team/'>
                    <img className='width-30 margin-left-50 margin-left-35 margin-top-30 margin-bottom-10 opacity-08 opacity-1-hov' src={instagram} alt='Instagram' />
                  </a>
                </div>
              </div>
              <div className='row rows-12'>
                <small className='col text-center font-size-1 margin-left-8'>© 2024 1RCP-Milsim-Team</small>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
