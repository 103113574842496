import eagleImg from '../img/spe/eagle-spe.webp';

const Eagle = () => {
    return(
        <section className='container'>
            <h2 className='border-bottom-black font-size-3 padding-bottom-1 margin-top-3 margin-bottom-3 color-black'>
                Eagle :
            </h2>

            <div className='row'>
                <p className='col-sm-12 col-md font-size-2 color-green'> 
                    L'unité Eagle est constituée de plusieurs appareils aérien bijoux technologiques et fleuron de nos 
                    armées. Elle mène des missions de reconnaissance aérienne, soutien les troupes au sol, détruit des 
                    cibles à haute valeur, apporte du soutien sanitaire et bien d'autres actions.
                </p>
                <div className='col-sm-12 col-md'>
                    <img src={eagleImg} alt='Eagle en action' className='border-black width-100' />
                </div>
            </div>

            <h2 className='border-bottom-black font-size-3 padding-top-3 padding-bottom-1 margin-top-3 margin-bottom-3 color-black'>
                Sous spécialisations :
            </h2>

            <div className='row font-size-1-5'>

                <div className='col-sm-6 col-md-4'>
                    <h3 className='font-size-2-5'>Hélicoptère :</h3>
                    <ul className='color-green'>
                        <li>Assistant machine mitrailleuse</li>
                        <li>Pilote de combat</li>
                        <li>Pilote evasan</li>
                        <li>Pilote logistique</li>
                        <li>Opérateur aérien</li>
                    </ul>
                </div>

                <div className='col-sm-6 col-md-4'>
                    <h3 className='font-size-2-5'>Aviation :</h3>
                    <ul className='color-green'>
                        <li>Pilote de chasse</li>
                        <li>Co-pilote</li>
                    </ul>
                </div>

                <div className='col-sm-6 col-md-4'></div>
                
            </div>
        </section>
    )
}

export default Eagle;