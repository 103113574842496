import helico from '../img/82/helico.png';
import boom from '../img/82/boom.png';
import { useState } from 'react';

const Ea = () => {

    const [isActive, setActive] = useState("false");

    const display = () => {
        setActive(!isActive);
  };

    return(
        <section className='container'>
            <div className={isActive ? 'col display-block' : 'col display-none'}>
                <p className='font-size-3-5 margin-top-3 text-center'>Touche l'hélico de Meloup</p>
                
                <div className='row'>
                    <div className='col'></div>
                    <img onClick={display} src={helico} alt='Helico de Meloup' className='col width-30' />
                    <div className='col'></div>
                </div>
            </div>

            <div className={isActive ? 'col display-none' : 'col display-block'}>
                <p className='font-size-3-5 margin-top-3 text-center'>Ah bah voilà, il s'est encore crashé !</p>
                
                <div className='row'>
                    <div className='col'></div>
                    <img src={boom} alt='Explosion' className='col width-30' />
                    <div className='col'></div>
                </div>
            </div>
            <div className='padding-top-85-mobile'></div>
        </section>
    )
}

export default Ea;