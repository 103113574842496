import NotFoundImg from '../img/NotFoundImg.png';

const NotFound = () => {
    return (
        <section className='container'>
            <div className='row'>
                <h2 className='col-md-6 offset-2 font-size-3-5 margin-top-8 color-green'>Erreur 404 : Page introuvable</h2>
                <img className='col-md-2 margin-top-3' src={NotFoundImg} alt='Erreur 404' />
            </div>
            <div className='padding-top-7-mobile'></div>
        </section>
    )
}

export default NotFound;